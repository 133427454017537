// --------------------------------------------------
//
//      SIGNIN PAGE
//      Created for Framework 1.0.0
//
// --------------------------------------------------

.signin-page{

	.signin-registration-image{
		background: url(../img/signin-side-img.png) no-repeat center left; 

	}

}