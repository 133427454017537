// --------------------------------------------------
//
//      INFO-BLOCK
//      Created for Framework 1.0.0
//
// --------------------------------------------------
.info-blocks{
	@extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .flex-column;
}
.info-block{
	@extend .block;
	@extend .d-flex;
	@extend .flex-column;
	@extend .p-4;
    @extend .mb-3;
	@extend .w-100;
	border-top: 3px solid $brand-2;

	.info-block-heading{
		@extend .pb-2;
		@extend .h3-heading;

	}
	.info-block-body{
		@extend .pb-3;
		@extend .text-start;
		@extend .b4-text;
	}
	a{
		@extend .ps-1;
		color: $brand-3;
		&:hover, &:focus{
	       color: darken( $brand-3, 40% );
	    }
	}

}