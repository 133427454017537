// --------------------------------------------------
//
//      ACCOUNT PAGE
//      Created for Framework 1.0.0
//
// --------------------------------------------------


.account-page{

	.accordion{
		@extend .accordion-flush;

		.accordion-item{
			@extend .block;
			@extend .mb-3;
		}
		.accordion-button{
			@extend .p-3;
			@extend .justify-content-between;
			border-radius: $border-radius-lg!important;

			&:not(.collapsed) {
			    color: $brand-1;
			    background-color: transparent;
			    box-shadow: none;
			}

			.account-name{
				@extend .text-truncate;
				@extend .d-flex;
	    		@extend .align-items-center;

				&.gas-account{
					position: relative;						
					
					&:before{
						content: " ";
					    position: relative;
					    display: inline-block;
					    background: url(../img/gas-icon.png) no-repeat;
					    width: 24px;
					    height: 30px;
					    background-size: 100%;
					    margin-right: 7px;

					}
				}
				&.energy-account{
					position: relative;						
					
					&:before{
						content: " ";
					    position: relative;
					    display: inline-block;
					    background: url(../img/energy-icon.png) no-repeat;
					    width: 24px;
					    height: 30px;
					    background-size: 100%;
					    margin-right: 7px;

					}
				}
			}
		}
		.accordion-header{
			
		}
		.accordion-body{
			border-top: 1px solid $gray-5;
			@extend .d-flex;
			@extend .flex-column;
			
			.account-location-container, .account-rebate-date-container{
				@extend .d-flex;
	    		
	    		.account-rebate-date-heading, .account-location-heading{
	    			@extend .pe-2;
	    			@extend .b4-text;
	    			font-weight: bold;
	    		}

	    		.account-rebate-date, .account-location{
	    			@extend .b4-text;
	    		}
			}
			.account-usage-chart-container{
				@extend .pt-3;

				.account-usage-chart-heading{
					@extend .h4-heading;
					@extend .pb-2;
				}
				canvas{
					max-height: 400px;
				}
			}
		}
		.accordion-button::after {
    		@extend .d-none;
		}
	}

	.social-links-contianer{
		@extend .d-flex;
	    @extend .align-items-center;
	    @extend .justify-content-center;
	    @extend .flex-column;
	}

	.pagination{
		@extend .justify-content-center;

		.page-link{
			color: $brand-3;
			border-color:$gray-5;
		}

	}

}