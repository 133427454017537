// --------------------------------------------------
//
//		BUTTONS
//		Created for Framework 1.0.0
//
// --------------------------------------------------

.brand-button-1{
	@extend .py-2;
	@extend .px-4;
	@extend .text-uppercase;
	background: $brand-1;
	border: 1px solid $brand-1;
	color: $white;
	border-radius: 50px;

	&:hover{
		background: lighten($brand-1, 72%);
		color: $brand-1;
		border-color: $brand-1;
	}
}


.brand-button-white{
	@extend .py-2;
	@extend .px-3;
	background: $white;
	border: 1px solid $gray-5;
	box-shadow: 0 0.2rem .8rem rgba(0, 0, 0, .10%);
	color:  $gray-2;
	border-radius: 50px;

	&:hover, &:focus{
		background: $white;
		color:  $gray-2;
		box-shadow: 0 0.2rem .8rem rgba(0, 0, 0, .20%);

	}
}