// --------------------------------------------------
//
//      BLOCKS
//      Created for Framework 1.0.0
//
// --------------------------------------------------


.block{
	background: $white;
	border: 1px solid $gray-5;
	border-radius: $border-radius-lg;
	box-shadow: $box-shadow-sm;

}