

.help-page{

	.help-contact-form-container{
		@extend .block;
		@extend .mb-3;
		.help-contact-form-img{
			background: url(../img/apg-office-img.png) no-repeat center center; 
			border-radius: 0.3rem 0.3rem 0 0;
			background-size: cover;
			width: 100%;
			height: 300px;

			@include media-breakpoint-down(md) {  
		      	height: 200px;
		    }

		} 

		.help-contact-form-head{
			@extend .pb-3;

			.help-contact-form-heading{
				@extend .h2-heading;
			}
			.help-contact-form-subheading{
				@extend .b3-text;
			}
		}

		.help-contact-form{
			@extend .p-3;

			.help-contact-form-row{
				@extend .d-flex;
				@extend .flex-column;
				@extend .flex-md-row;

				.form-floating:first-child {
					@extend .pe-md-3;
					@extend .pe-0;
				}
			}
			.form-floating{
				flex:1;
				@extend .pb-3;

			}
			.help-contact-form-button{
				@extend .d-flex;
    			@extend .justify-content-end;
			}
		}
		
	}
}