// --------------------------------------------------
//
//		TYPOGRAPHY
//		Created for Framework 1.0.0
//
// --------------------------------------------------

//open sans on all content
* {
    font-family: 'Open Sans', sans-serif;
    color: #333333;
}


.h1-page-heading{
	font-size: $font-lg;
	color: $brand-1;
	font-weight: 400;
}

.h1-heading{
	font-size: $font-xl;
	font-weight: 400;
}
.h2-heading{
	font-size: $font-lg;
	font-weight: 400;
}
.h3-heading{
	font-size: $font-md;
	font-weight: 400;
}
.h4-heading{
	font-size: $font-sm;
	font-weight: 400;
}
.h5-heading{
	font-size: $font-xs;
	font-weight: 400;
}

.b1-text{
	font-size: $font-lg;
	font-weight: 400;
}
.b2-text{
	font-size: $font-md;
	font-weight: 400;
}
.b3-text{
	font-size: $font-sm;
	font-weight: 400;
}
.b4-text{
	font-size: $font-xs;
	font-weight: 400;
}