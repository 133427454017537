

//Bootstrap 5.1


@import "node_modules/bootstrap/scss/bootstrap";

//Bootstrap icons 
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");


//Custom framework

//abstracts
@import "abstracts/variables";

//core
@import "core/typography";

//components
@import "components/links";
@import "components/buttons";
@import "components/blocks";
@import "components/line-behind";
@import "components/social-links";

//layout
@import "layout/navigation";
@import "layout/header";
@import "layout/main";
@import "layout/footer";
@import "layout/signin-registration";

//pages
@import "pages/signin";
@import "pages/registration";
@import "pages/account";
@import "pages/loyalty";
@import "pages/carbon-calculator";
@import "pages/help";


//sections
@import "sections/attention-block";
@import "sections/info-block";




