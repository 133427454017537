// --------------------------------------------------
//
//      SOCIAL-LINKS
//      Created for Framework 1.0.0
//
// --------------------------------------------------

.social-links{
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .flex-row;
}
.social-link{
	@extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .my-3;
    @extend .mx-1;
	color: $brand-3;
	background: $white;
	border: 1px solid $brand-3;
    text-align: center;
    text-decoration: none;
    width: 50px;
    border-radius: 100%;
    height: 50px;
    &:hover, &:active, &:focus{
    	color: $white;
		background: $brand-3;
    }
    &:hover i, &:active i, &:focus i{
        color: $white;
    }
   i{
   		font-size: 25px;
        color: $brand-3;
   }

}