// --------------------------------------------------
//
//		HEADER
//		Created for Framework 1.0.0
//
// --------------------------------------------------


.portal-page{
	background: $body-background;
	
}
.portal-header{
	@extend .py-2;
	@extend .border-bottom;
	@extend .sticky-lg-top;
	box-shadow: $box-shadow-sm;
	background: $white;

	.portal-header-container{
		@extend .d-flex;
		@extend .justify-content-between;
		@extend .align-items-center;
		max-width: 1900px;

	}
	.header-logo{
		@extend .px-3;
		img{
			@extend .h-100;
			@extend .w-100;
			max-width: 200px;
		}

	}

	.portal-header-actions{
		@extend .d-flex;
		@extend .align-items-center;

		.header-user-info{
			@extend .d-flex;
			@extend .align-items-center;

			.header-user-icon{
				@extend .rounded-circle;
				@extend .me-2;
				border: 1px solid $gray-1;
				img{
					@extend .h-100;
					@extend .w-100;
					max-width: 28px;
				}
			}
			.dropdown-menu{
				@extend .block;

				.dropdown-item{
					color: $brand-3;
					&:hover, &:focus {
						color: $brand-3;
				    	background-color: $gray-5;
					}
				}
			}
			button{
				background: transparent;
				border:none;
				@extend .d-flex;
			 	@extend .align-items-center;
			 	@extend .px-2;
			 	@extend .py-2;
			 	@extend .me-3;

			 	&.show{
			 		background: $gray-5;
			 		border-radius: $border-radius-lg;
			 	}

				&:after {	
				    display: inline-block;
				    margin-left: 0.255em;
				    background: url(../img/chevron-down.svg) no-repeat; 
				    content: "";
				    position: relative;
				    width: 16px;
				    height: 16px;
				    border: none;
				}

			}

			.header-user-name{
				color: $gray-3;
				font-size: .8rem;

			}
		}

		.header-logout-mobileNav-buttons{

			.header-logout-button{
				background: transparent;
				border: none;
				@extend .mx-3;

				.logout-icon{
					fill:$gray-1;
				}

			}
			.header-mobileNav-button{
				@extend .me-3;

			}
		}

	}
	
}