// --------------------------------------------------
//
//      CARBON CALCULATOR
//      Created for Framework 1.0.0
//
// --------------------------------------------------

.carbon-calculator-page{

	.calculator-field-heading{
		@extend .d-flex;
		@extend .flex-sm-row;
		@extend .flex-column;
		@extend .align-items-center;
		@extend .px-3;
		@extend .py-2;

		.label{
			@extend .col-sm-6;
			@extend .col-12;
		}

		.input{
			@extend .col-sm-3;
			@extend .col-12;
		}
		
		.result{
			@extend .col-sm-3;
			@extend .col-12;
			@extend .ps-sm-3;
			@extend .ps-0;
		}
	}

 	.calculator-results-heading{
 		@extend .d-flex;
		@extend .flex-row;
		@extend .align-items-center;
		@extend .px-3;
		@extend .py-2;
 	}

	.carbon-calculator{
		@extend .block;

		.calculator-field{
			@extend .d-flex;
			@extend .flex-sm-row;
			@extend .flex-column;
			@extend .align-items-center;
			@extend .py-2;
			@extend .px-3;
			border-bottom: 1px solid $gray-5;
			.label{
    			@extend .col-sm-6;
    			@extend .col-12;
    			@extend .text-truncate;
			}
			.input{
				@extend .col-sm-3;
				@extend .col-12;
				@extend .my-sm-0;
				@extend .my-2;

				input{
					max-width: 200px;

					@include media-breakpoint-down(sm) {  
				      	max-width: 100%;
				    }
				}
			}
			.result{
				@extend .col-sm-3;
				@extend .col-12;
				@extend .ps-sm-3;
				@extend .ps-0;
			}

		}
	}
	.calculator-results{
		@extend .block;

		.calculator-result-field{
			@extend .d-flex;
			@extend .flex-column;
			@extend .align-items-center;
			@extend .px-3;
			@extend .py-5;
			@extend .text-center;

			.label{
				@extend .b2-text;
			}
			.result{
				color: $brand-2;
				font-weight: bold;
				font-size: 2.5rem;
			}

		}
	}

}