// --------------------------------------------------
//
//		VARIABLES
//		Created for Framework 1.0.0
//
// --------------------------------------------------


//Branding colors

$brand-1:				#231E5A;
$brand-2:				#98C33C;
$brand-3:			    #0D7ABD;
$body-background:		#F6F6F6; 

//Social 
$facebook: 				#4267B2;

//Grays 
$gray-1:				#919191; // Icons and large text on white
$gray-2:				#757575; // Icons,large and small text on white
$gray-3:				#636363; // Icons,large and small text on white
$gray-4:				#5C5C5C; // Icons,large and small text on white
$gray-5:				#F1F1F1; // Very light gray only for background and borders 			

//Font sizes 

$font-xl:				24px;
$font-lg:				21px;
$font-md:				18px;
$font-sm:				16px;
$font-xs:				14px;