// --------------------------------------------------
//
//      NAVIGATION
//      Created for Framework 1.0.0
//
// --------------------------------------------------

.portal-sidebar{
    background: $brand-1;
    color: $white;

    nav{
        .nav-section-headers{
            @extend .px-4;
            @extend .py-3;
            @extend .text-uppercase;
            font-size: $font-xs;
            color: $white;
            
        }

        .nav-link {
           @extend .d-flex;
           @extend .align-items-center;
           color: $white;
           font-size: $font-sm;
           padding-top: .7rem;
           padding-bottom: .7rem;
           padding-left: 2rem;

           &.active, &:hover{
                background: rgba(250, 250, 250, .15);
           }

           img{
            @extend .me-2;
           }
        }
    }

}

//offcanvaus 
.offcanvas-backdrop {
    display: none;
}
.offcanvas-start {
    top: 63px;
    left: 0;
    width: 280px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);

    @include media-breakpoint-up(lg) {  
        transform: none; 
        visibility: visible; 
    }
    @include media-breakpoint-down(sm) {  
        width: 100%;
    }
   
}


//mobileNav button
.mobileNav-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: escape-svg($navbar-light-toggler-icon-bg);
}

.header-mobileNav-button{
    border: 1px solid $gray-1;
    border-radius: $border-radius;
    padding: 2px 5px;
    background: transparent;
    @include media-breakpoint-up(lg) { display: none;}
}