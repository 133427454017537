// --------------------------------------------------
//
//      LINKS
//      Created for Framework 1.0.0
//
// --------------------------------------------------

a {
    color: $brand-1;
    text-decoration: none;

    &:hover, &:focus{
       color: darken( $brand-1, 40% );
    }
}