// --------------------------------------------------
//
//		LINE-BEHIND
//		Created for Framework 1.0.0
//
// --------------------------------------------------

.line-behind {
    position: relative;
    z-index: 1;
    
    &:before {
        border-top: 2px solid $gray-5;
        content:"";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 50%; left: 0; right: 0; bottom: 0;
        width: 95%;
        z-index: -1;
    }

    span { 
        /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
        background: #fff; 
        padding: 0 15px; 
    }
}