// --------------------------------------------------
//
//      LOYALTY
//      Created for Framework 1.0.0
//
// --------------------------------------------------



.iframe-container {
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    background: $body-background;
    height: 100vh;
    @include media-breakpoint-up(lg) {  
        margin-left: 280px;
    }
}

.iframe-container iframe {
   border: 0;
   height: 100vh;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   overflow: hidden;
   padding-bottom: 63px;
}