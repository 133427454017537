// --------------------------------------------------
//
//      MAIN BODY
//      Created for Framework 1.0.0
//
// --------------------------------------------------

.portal-page{
	main{
		@extend .p-lg-4;
		@extend .p-3;
		max-width: 1620px;
		@include media-breakpoint-up(lg) {  
	        margin-left: 280px;
	    }

	    .main-page-heading{
	    	@extend .d-flex;
	    	@extend .align-items-baseline;

	    	h1{
	    		@extend .pe-2;
	    	}
	    }
	    .main-col-container{
	    	@extend .d-flex;
	    	@extend .flex-xl-row;
			@extend .flex-column;
	   
	    	.main-left-col{
	    		flex: 0 0 70%; 
	    		@extend .me-xl-3;
	    		@extend .me-0;
	    		@include media-breakpoint-down(xl) {  
			        flex: 0 0 100%;
			    }
	    	}

	    	.main-right-col{
	    		flex: 0 0 30%; 
	    		@extend .me-xl-5;
	    		@extend .me-0;
	    		@include media-breakpoint-down(xl) {  
			        flex: 0 0 100%; 
			    }
	    	}

	    }
	}
}