// --------------------------------------------------
//
//      REGISTRATION PAGE
//      Created for Framework 1.0.0
//
// --------------------------------------------------

.registration-page{

	.signin-registration-image{
		background: url(../img/registration-side-img.png) no-repeat center left; 

	}

}