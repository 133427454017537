// --------------------------------------------------
//
//      ATTENTION-BLOCK
//      Created for Framework 1.0.0
//
// --------------------------------------------------
.attention-blocks{
	@extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .flex-column;
}
.attention-block{
	@extend .block;
	@extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
	@extend .flex-column;
	@extend .p-4;
    @extend .mb-3;
	@extend .w-100;

	.attention-block-heading{
		@extend .pb-3;
		@extend .h2-heading;
		color: $brand-1;

	}
	.attention-block-img{
		@extend .pb-3;

	}
	.attention-block-body{
		@extend .pb-3;
		@extend .text-center;
		@extend .b4-text;
	}
	.attention-block-button{
		@extend .brand-button-1;

	}

}