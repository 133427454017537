// --------------------------------------------------
//
//      SIGNIN REGISTRATION PAGE
//      Created for Framework 1.0.0
//
// --------------------------------------------------
.signin-registration-body{
	@extend .d-flex;
	@extend .justify-content-center;
	@extend .align-items-center;
	@extend .flex-column;
	@extend .px-3;
	background: $body-background;
	height: 100vh;

	@include media-breakpoint-down(md) {  
      	height: auto;
    }

}
.signin-registration-container{
	@extend .m-3;
	@extend .block;
	@extend .d-flex;
	@extend .w-100;
	@extend .flex-md-row;
	@extend .flex-column;
	max-width: 960px;

	@include media-breakpoint-down(md) {  
      	max-width: 500px;
    }

	.signin-registration-header{
		@extend .d-flex;
		@extend .justify-content-center;

		.signin-registration-header-logo{

			img{
				max-width: 200px;
				@extend .w-100;
			}
		}
	}

	.signin-registration-content{
		@extend .d-flex;
		@extend .flex-column;
		@extend .px-sm-3;
		@extend .px-0;
		@extend .py-sm-5;
		@extend .py-3;
		flex: 0 0 60%;

		@include media-breakpoint-down(md) {  
	      	flex: 0 0 100%;
	    }

		.signin-registration-main{
			@extend .w-100;
			@extend .m-auto;
			@extend .text-center;
			max-width: 350px;

			.signin-registration-main-heading{
				@extend .text-center;
				@extend .py-4;

				h1{
					@extend .h1-heading;
					color: $gray-3;
				}

				.signin-registration-main-subheading{
					@extend .b3-text;
					color: $gray-3;
				}
				
			}

			.social-signin-registration-buttons{
				@extend .d-flex;
				@extend .justify-content-center;

				.social-signin-registration-button{
					@extend .brand-button-white;
					@extend .mx-2;
					@extend .d-flex;
					@extend .align-items-center;
					@extend .flex-row;
					min-width: 133px;

					@include media-breakpoint-down(sm) {  
					    justify-content: center;
					    position: relative;
					    margin: .5rem;
				    }

					svg{
						@extend .me-2;
						width: 20px;
						height: 20px;

						@include media-breakpoint-down(sm) { 
					    	position: absolute;
						    left: 10px;
						    width: 25px;
						    height: 25px;
						}

						&.bi-facebook{
							fill:	$facebook;
						}
					}
				}
				@include media-breakpoint-down(sm) {  
			      	flex-direction: column;
			    }
			}

			.title-divider{
				@extend .py-4;
				@extend .b3-text;
			}

			form{
				.form-floating{
					@extend .mb-3;
					@extend .text-start;
				}
				button.brand-button-1{
					@extend .w-100;
					@extend .mb-3;
				}
			}

		}

	}
	.signin-registration-password-input{
        
        &.show-hide-password{

            input{
                padding-right: 50px;
            }

             .password-show-hide-button{
                @extend .position-absolute;
                @extend .d-flex;
                @extend .justify-content-center;
                @extend .align-items-center;
                background: transparent;
                border: none;
                width: 50px!important;
                height: 60px;
                top: 0;
                right: 0;

                .password-icon{
                    fill: #777;
                }
            }  
        }
    }
	.signin-registration-image{
		border-radius: 0 0.3rem 0.3rem 0;
		background-size: cover;
		flex: 0 0 40%; 

		@include media-breakpoint-down(md) {  
	      	display: none;
	    }

	}
}
footer{
	@extend .d-flex;
	@extend .flex-column;
	@extend .flex-md-row;
	@extend .text-center;
	@extend .pb-4;
	color: $gray-3;


	.copyright{
		@extend .px-0;
		@extend .px-md-2;
		@extend .b3-text;
		color: $gray-3;
	}
}